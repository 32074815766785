import { colors, gridBreakpoints } from '@blissbook/ui/branding'
import { Col, Container, ForwardLink, Link, Row } from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import tinycolor from 'tinycolor2'
import { Layout, MaxWidthContainer, Section, Typography } from '../components'
import { CallToActionSection, SolutionSection } from './sections'

const backgroundColor = tinycolor(colors['aqua-100'])
  .setAlpha(0.5)
  .toRgbString()
const color = 'aqua-500'

const featureIcon = (type) => (
  <FontAwesomeIcon
    icon={type}
    className='tw-mr-4 tw-mb-10'
    color={colors[color]}
    style={{
      fontSize: 80,
    }}
  />
)

const FEATURES = [
  {
    icon: (
      <span style={{ position: 'relative' }}>
        {featureIcon(['fal', 'envelope'])}
        <span
          style={{
            position: 'absolute',
            right: 5,
            top: -55,
            backgroundColor: colors[color],
            color: 'white',
            fontSize: 14,
            lineHeight: '14px',
            verticalAlign: 'top',
            borderRadius: 3,
            padding: '1px 5px 3px 5px',
          }}
        >
          CC:
        </span>
      </span>
    ),
    title: 'CC managers on reminder emails.',
    description:
      'Risk mitigation shouldn’t rest solely on HR. Make sure managers know which of their direct reports are non-compliant by CCing them on automated reminders.',
  },
  {
    icon: featureIcon(['fal', 'file-csv']),
    title: 'Generate reports by manager.',
    description: `Want to see the signature status of a specific manager's staff? Filter reporting by manager and export to CSV to let them know how they're doing.`,
  },
  {
    icon: featureIcon(['fal', 'file-chart-pie']),
    title: 'Self-serve reporting for managers.',
    description:
      'Managers can view signature status for their direct reports from their own customized dashboard.',
  },
  {
    icon: featureIcon(['fal', 'calendar-day']),
    title: 'Send managers a weekly digest.',
    description: `Email a weekly report to managers, containing a list of their direct reports who've yet to sign their acknowledgement.`,
  },
]

export default () => (
  <Layout
    title={`Manager Participation: Mitigate policy risk with managers' help`}
    description={
      'Policy management that involves managers does a better job mitigating risk and improves compliance and governance.'
    }
  >
    <SolutionSection
      color={color}
      image='OrgChart.svg'
      imageDesktopCss={css(`
          top: 70px;
          right: -140px;
          height: 400px;

          @media (min-width: ${gridBreakpoints.xl}px) {
            right: -100px;
          }
        `)}
      imageMobileCss={css(`
          transform: scaleX(-1);
        `)}
      greeting='Automated Manager Escalation'
      title='Mitigating risk isn’t only HR’s job.'
      description='Ensure employee compliance and help managers help themselves. Involve "the business" to complete your policy compliance work and mitigate risk. Blissbook does the tactical “hall monitor” work for you so you can focus on being a strategic partner.'
    />

    <Section
      css={{
        background: `linear-gradient(180deg, white 0%, ${backgroundColor} 100%);`,
      }}
    >
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <Row>
            {FEATURES.map((feature, index) => (
              <Col key={index} xs={12} md={6} className='tw-mb-10'>
                <MaxWidthContainer md={400}>
                  <If condition={feature.icon}>{feature.icon}</If>
                  <If condition={feature.image}>
                    <img
                      alt={feature.image}
                      src={feature.image}
                      style={{ fill: colors['blurple-500'] }}
                      className='tw-mb-10'
                      css={{ height: 80 }}
                    />
                  </If>
                  <Typography variant='h2'>{feature.title}</Typography>
                  <Typography variant='p2'>{feature.description}</Typography>
                </MaxWidthContainer>
              </Col>
            ))}
          </Row>
        </MaxWidthContainer>
      </Container>
    </Section>

    <CallToActionSection
      title='Ready to get managers on the same page?'
      description='We’re here to help! Go ahead and try it (free), or we can walk you through it (also free). Either way, we know you’ll love it.'
      contentClassName=''
      includeLogos
    >
      <div className='md:tw-flex tw-items-center tw-mb-8'>
        <Link className='btn btn-primary tw-mr-6' to='/pricing'>
          Try it free
        </Link>
        <ForwardLink className='tw-block tw-my-6' to='/request-a-demo'>
          Request a demo
        </ForwardLink>
      </div>
    </CallToActionSection>
  </Layout>
)
