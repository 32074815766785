import { formatSubdomain } from '@blissbook/lib/sanitize/domain'
import { Store } from '@blissbook/ui/util/store'
import jsonp from 'jsonp'

export const setAuthValue = (key: string, value: any) => {
  Store.instance.produce((draft) => {
    // Update the target value
    const { auth } = draft
    auth[key] = value

    // If we need to update the subdomain
    const { subdomainDirty } = auth
    if (key === 'company' && !subdomainDirty) {
      const subdomain = formatSubdomain(value)
      auth.subdomain = subdomain
    } else if (key === 'subdomain') {
      auth.subdomainDirty = true
    }
  })
}

export const setSignUpStep = (step: number) => {
  Store.instance.produce((draft) => {
    const { signUp } = draft
    signUp.maxStep = Math.max(step, signUp.maxStep)
    signUp.step = step
  })
}

export const setShowRedirectSuggestion = (show: boolean) => {
  Store.instance.produce((draft) => {
    const { signUp } = draft
    signUp.showRedirectSuggestion = show
  })
}

// Subscribe to the mailchimp newsletter
export const subscribeToNewsletter = ({
  email,
  name,
}: {
  email: string
  name: string
}) =>
  new Promise((resolve, reject) => {
    const baseUrl =
      '//blissbook.us2.list-manage.com/subscribe/post?u=7cace63e3b7012c7fe527e4aa&id=cf9824f824'
    const params = `&EMAIL=${encodeURIComponent(
      email,
    )}&FNAME=${encodeURIComponent(name)}`
    const url = baseUrl.replace('/post?', '/post-json?') + params
    jsonp(
      url,
      {
        param: 'c',
      },
      (err: Error, data: any) => {
        if (err) {
          reject(err)
        } else if (data.result !== 'success') {
          reject(data.msg)
        } else {
          resolve(data.msg)
        }
      },
    )
  })
