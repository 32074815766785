import { sanitizeHtml } from '@blissbook/lib/sanitize'
import { blogUrl } from '@blissbook/ui-config'
import { Alert, Button, Field, Link } from '@blissbook/ui/lib'
import React from 'react'
import {
  Layout,
  MailchimpForm,
  ResourceLink,
  ResourceSection,
  Typography,
} from '../components'

export default () => (
  <Layout
    title='HR, Policy Management and Employee Handbook News from Blissbook'
    description={`'Blissbook’s newsletter helps you become a better HR pro, leader, and person. Useful articles and resources to help you write better HR policies for employees.`}
  >
    <ResourceSection
      images={['/images/marketing/resources/newsletter.jpg']}
      imageFooter={
        <Typography
          variant='p2'
          className='text-center tw-mx-auto'
          style={{ maxWidth: 250 }}
        >
          Check out past newsletters on{' '}
          <Link href={blogUrl} target='_blank'>
            our blog
          </Link>
          !
        </Typography>
      }
    >
      <img
        src='/images/marketing/Bugle.svg'
        className='tw-mb-10'
        alt='a bugle icon in blue and yellow'
      />

      <Typography variant='h4'>The Newsletter</Typography>

      <Typography variant='h1'>
        The best HR content delivered to your inbox every other Friday.
      </Typography>

      <Typography variant='p2'>
        Become a better HR pro, leader, and person. We’ll send the most useful
        articles and resources we find – content to help you protect your
        company and show employees they’re valued (and some things just for
        fun).
      </Typography>

      <MailchimpForm>
        {({
          handleSubmit,
          status: { hasSubmitted, isSubmitting, lastError } = {},
          values,
        }) => (
          <form
            noValidate
            className='tw-mb-10'
            css={{ maxWidth: 500 }}
            onSubmit={handleSubmit}
          >
            <If condition={lastError}>
              <Alert
                type='danger'
                variant='large'
                // biome-ignore lint/security/noDangerouslySetInnerHtml: useAnchorContent: requires some work (div is onclick)
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(lastError) }}
              />
            </If>

            <fieldset disabled={isSubmitting || hasSubmitted}>
              <Field name='name' label='First Name' />
              <Field name='email' label='Email Address' />
              <Button
                type='submit'
                className='tw-mt-6'
                color={hasSubmitted ? 'success' : 'primary'}
              >
                {hasSubmitted
                  ? `Success! You’re gettin’ the Bugle, ${values.name}.`
                  : 'Subscribe to The Blissbook Bugle'}
              </Button>
            </fieldset>
          </form>
        )}
      </MailchimpForm>

      <ResourceLink
        to='/how-to-write-a-culture-first-employee-handbook'
        icon={['far', 'book-heart']}
      >
        Bonus Round! Get your mitts on our free eBook:{' '}
        <em>How to Write a Culture-First Employee Handbook</em>
      </ResourceLink>
    </ResourceSection>
  </Layout>
)
