import { colors } from '@blissbook/ui/branding'
import {
  BackgroundImage,
  DesktopBrowser,
  FadeOutInTransition,
  Link,
  Shape,
  ShapeGrid,
} from '@blissbook/ui/lib'
import {
  useParallax,
  useParallaxScreenRatio,
} from '@blissbook/ui/util/parallax'
import { css } from '@emotion/react'
import range from 'lodash/range'
import React, { useEffect, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import tinycolor from 'tinycolor2'
import { MaxWidthContainer, Section, Typography } from '../../components'

const imageFolder = '/images/marketing/customers'
const imageKeys = ['handbook', 'logo', 'photo']
const logoHeight = 50
const transitionDuration = 400
const transitionDelayMs = 6000

const TESTIMONIALS = [
  {
    id: 'cengage',
    color: '#0085CA',
    company: 'Cengage',
    companyUrl: 'https://www.cengage.com',
    handbook: 'cengage-handbook.jpg',
    isMobile: true,
    logo: 'cengage-logo.png',
    name: 'Amy Turner',
    photo: 'amy.jpg',
    quote:
      'Investing in Blissbook and the Trailblazer’s Guide is a decision we’ve never second-guessed. The Guide looks awesome, employees love it, and the process to collect and audit employee signatures (now electronically!) has never been easier. A major improvement and advancement for employee handbooks!',
    title: 'Director, HR Operations',
  },
  {
    id: 'slalom',
    color: '#0C62FB',
    company: 'Slalom',
    companyUrl: 'https://slalom.com',
    handbook: 'slalom.jpg',
    isMobile: true,
    logo: 'slalom-logo.svg',
    name: 'Mel Murphy',
    photo: 'mel.jpg',
    quote:
      'Blissbook went above and beyond our already high expectations, responded with kindness to no less than a million questions, phone calls (some just for emotional support), emails, late nights, customization and design requests, and all with an extremely tight deadline. From beginning to execution to post-launch, support has been incredible. The result of this hard work is a beautiful global handbook we are so proud of and that is very Slalom.',
    title: 'Policy and Compliance Program Manager, Global People Team',
  },
  {
    id: 'ashley',
    color: '#F8840D',
    company: 'Ashley Furniture Industries',
    companyUrl: 'https://ashleyfurniture.com',
    handbook: 'ashley-handbook.jpg',
    logo: 'ashley-logo.png',
    name: 'Kris Cegielski',
    photo: 'kris.jpg',
    quote:
      'Blissbook has been an outstanding partner when creating our first electronic handbook. Going into the process, we knew we needed help coding and organizing all of the different polices for our employees. They assisted with building the content, but more importantly taught us how to continuously update the content ourselves. When new challenges arose that might hinder us, they came through with a solution to make our handbook even better!',
    title: 'VP HR Business Partner',
  },
  {
    id: 'abb',
    color: '#ff000f',
    company: 'ABB',
    companyUrl: 'https://global.abb/group/en/about',
    handbook: 'abb-handbook.jpg',
    isMobile: true,
    logo: 'abb-logo.svg',
    name: 'Amy Garlick',
    photo: 'amy-garlick.jpg',
    quote:
      'The thought of creating a digital handbook was overwhelming considering I’m not tech-savvy. With Blissbook, it couldn’t have been easier. The creative team was terrific to work with and I felt completely supported along the way! The finished handbook looks professional and is easy to navigate and update. What a great resource we now have for our employees!',
    title: 'Senior Manager, Employee Relations',
  },
  {
    id: 'swbc',
    color: '#28334A',
    company: 'SWBC',
    companyUrl: 'https://www.swbc.com',
    handbook: 'swbc-handbook.jpg',
    isMobile: true,
    logo: 'swbc-logo.svg',
    name: 'Mystel Duke',
    photo: 'mystel.jpg',
    quote: `Blissbook replaced our custom in-house policy management platform. They built custom features in a timely manner and we love how easy it is to update content, communicate changes with employees, and report on acknowledgements. We couldn't be happier!`,
    title: 'Senior Vice President, Human Resources',
  },
  {
    id: 'accolade',
    color: '#279E92',
    company: 'Accolade',
    companyUrl: 'https://www.accolade.com',
    handbook: 'accolade-handbook-2.jpg',
    logo: 'accolade-logo.png',
    name: 'Laura Janssen',
    photo: 'laura.jpg',
    quote:
      'Culture is so important for us at Accolade and it’s important for our employee handbook to truly reflect that. Blissbook made it so easy. The handbook is engaging for our tech-savvy employees and we never have to worry if we have the documentation we need.',
    title: 'Manager, HR Processes & Analytics',
  },
  {
    id: 'rra',
    color: '#002E5D',
    company: 'Russell Reynolds Associates',
    companyUrl: 'https://www.russellreynolds.com',
    handbook: 'rra-handbook.jpg',
    logo: 'rra-logo.png',
    name: 'Keesha-Ann Murphy',
    photo: 'keesha-ann.jpg',
    quote:
      'We’re so glad we decided to work with Blissbook when we replaced our PDF handbook. Yes, the product is great. More importantly, when we need help, they make us feel like we’re their only customer.',
    title: 'Human Capital Generalist',
  },
  {
    id: 'caspers',
    color: '#B8232F',
    company: 'Caspers',
    companyUrl: 'https://www.casperscompany.com',
    handbook: 'caspers-handbook.jpg',
    logo: 'caspers-logo.png',
    name: 'Kim Scott',
    photo: 'kim.jpg',
    quote:
      'Putting our employee manual online has been a game changer. The ability to connect with employees across 64 worksites and provide searchable, attractive, and easy-to-use content on a mobile-friendly platform like Blissbook is a great advantage. I highly recommend.',
    title: 'VP Human Resources',
  },
  {
    id: 'daxko',
    color: '#70B6B2',
    company: 'Daxko',
    companyUrl: 'https://daxko.com',
    handbook: 'daxko-handbook.jpg',
    logo: 'daxko-logo.png',
    name: 'Concetta Lewis',
    photo: 'concetta.jpg',
    quote:
      'We love how easy it is to update our Team Member Handbook and the annotations feature really simplifies communicating changes with employees. Support is great and it’s nice how Blissbook is always improving!',
    title: 'Director of People Development',
  },
  {
    id: 'blueprism',
    color: '#0F7DC2',
    company: 'Blue Prism',
    companyUrl: 'https://www.blueprism.com',
    handbook: 'blueprism-handbook.jpg',
    logo: 'blueprism-logo.png',
    name: 'Lindsey Kucharski',
    photo: 'lindsey.jpg',
    quote:
      'Our People Book is a simple, interactive home base for all our global HR policies. It’s easy for staff all over the world to access privately and we love how we can update it ourselves, anytime we want. We highly recommend Blissbook!',
    title: 'HR Manager, US & LATAM',
  },
  {
    id: 'actnano',
    color: '#2391F6',
    company: 'ActNano',
    companyUrl: 'https://actnano.com',
    handbook: 'actnano-handbook.jpg',
    logo: 'actnano-logo.png',
    name: 'Judi Martens',
    photo: 'judi-martens.jpg',
    quote: `When I needed a modern, fun and interactive handbook, Blissbook really came through. Not only is the product and platform great, the team made sure everything flowed smoothly throughout the project and provides exceptional support. We are in year two now and this was by far the best decision I've made. Easy to update and stay HR compliant with the tool.`,
    title: 'People, Culture and Office Manager',
  },
  {
    id: 'wd40',
    color: '#133C8B',
    company: 'WD-40 Company',
    companyUrl: 'https://www.wd40company.com',
    handbook: 'wd40-handbook.jpg',
    isMobile: true,
    logo: 'wd40-logo.png',
    name: 'Roxanne Casebier',
    photo: 'roxanne.jpg',
    quote:
      'We went from first contact with Blissbook to launching a beautiful, on-brand handbook in 6 weeks... over the holidays! The support team is always there for us when we need help and we’ve gotten lots of kudos from employees. Blissbook is awesome.',
    title: 'Director, Global Learning and Development',
  },
].map((testimonial) => {
  const { id } = testimonial

  // Clean up the images
  imageKeys.forEach((key) => {
    testimonial[key] = `${imageFolder}/${id}/${testimonial[key]}`
  })

  return testimonial
})

// Helper to get the testimonial at this index
const mod = (n, m) => ((n % m) + m) % m
const getTestimonial = (index) => {
  index = mod(index, TESTIMONIALS.length)
  return TESTIMONIALS[index]
}

const Logo = ({ isActive, src }) => (
  <div
    css={css`
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      &:after {
        content: '';
        position: absolute;
        left: 5px;
        right: 5px;
        bottom: -10px;
        border-top: 3px solid
          ${isActive ? colors['sunshine-500'] : 'transparent'};
        transition: all linear ${transitionDuration}ms;
      }
    `}
  >
    <img
      alt={src}
      css={css`
        filter: ${isActive ? 'none' : 'grayscale(100%)'};
        max-height: ${logoHeight}px;
        max-width: 120px;
        opacity: ${isActive ? 1 : 0.5};
        transition: all linear ${transitionDuration}ms;
        &:hover {
          filter: none;
        }
      `}
      src={src}
    />
  </div>
)

const Logos = ({ currentIndex, setCurrentIndex, ...props }) => {
  const logoCount = 5
  const cellWidth = 100 / logoCount
  const startIndex = currentIndex - logoCount
  const endIndex = currentIndex + logoCount * 2
  return (
    <div {...props} css={{ overflow: 'hidden' }}>
      <div
        css={{
          borderBottom: '10px solid transparent',
          height: logoHeight + 10,
          transition: `transform linear ${transitionDuration}ms`,
        }}
        style={{
          transform: `translateX(${-(currentIndex - 1) * cellWidth}%)`,
        }}
      >
        {range(startIndex, endIndex).map((index) => {
          const testimonial = getTestimonial(index)
          return (
            <div
              key={index}
              className='tw-flex tw-items-center tw-justify-center'
              css={{
                position: 'absolute',
                top: 0,
                height: '100%',
                cursor: 'pointer',
                width: '20%',
              }}
              style={{
                left: `${index * cellWidth}%`,
              }}
              onClick={() => setCurrentIndex(index)}
              onKeyUp={(e) => e.key === 'Enter' && setCurrentIndex(index)}
            >
              <Logo src={testimonial.logo} isActive={index === currentIndex} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const HandbookImage = ({ scrollY, style, ...props }) => {
  const [node, setNode] = useState()
  const height = useParallax(node, (node) => node.offsetHeight)
  const parentHeight = useParallax(node, (node) => node.parentNode.offsetHeight)

  // Bound the top
  if (height && parentHeight) {
    const maxScrollY = height - parentHeight
    scrollY = Math.min(scrollY, maxScrollY)
  }

  return (
    // biome-ignore lint/a11y/useAltText: if you see this line, try to fix it
    <img
      {...props}
      css={{
        transition: 'top 1ms linear',
      }}
      ref={setNode}
      style={{ ...style, top: -scrollY }}
    />
  )
}

const Profile = ({ testimonial, ...props }) => (
  <MaxWidthContainer {...props} className='tw-flex' xs='75%' lg='none'>
    <div className='tw-mr-4'>
      <BackgroundImage
        src={testimonial.photo}
        height={50}
        width={50}
        className='rounded-circle'
      />
    </div>
    <div>
      <Typography variant='h4' className='tw-mb-0 tw-text-gray-800'>
        {testimonial.name}
      </Typography>
      <Typography variant='p2' className='tw-mb-0'>
        <span className='tw-inline lg:tw-hidden'>{testimonial.title}</span>
        <span className='tw-hidden lg:tw-inline'>
          {testimonial.title} at{' '}
          <Link
            href={testimonial.companyUrl}
            css={{ color: 'inherit', '&:hover': { color: 'inherit' } }}
          >
            {testimonial.company}
          </Link>
        </span>
      </Typography>
    </div>
  </MaxWidthContainer>
)

const DesktopTestimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [inViewport, setInViewport] = useState(false)
  const [isAutomated, setAutomated] = useState(true)
  const [browserNode, setBrowserNode] = useState()
  const browserRatio = useParallaxScreenRatio(browserNode, {
    attachment: 'top',
    range: [0.5, -1],
  })
  const currentTestimonial = getTestimonial(currentIndex)

  // Automated scroll
  useEffect(() => {
    if (!isAutomated) return

    const timerId = setTimeout(
      () => setCurrentIndex(currentIndex + 1),
      transitionDelayMs,
    )
    return () => clearTimeout(timerId)
  }, [currentIndex, isAutomated])

  return (
    <Waypoint
      onEnter={({ waypointTop, waypointBottom }) => {
        // Don't fire if mobile
        if (waypointTop && waypointBottom) setInViewport(true)
      }}
    >
      <div className='tw-hidden lg:tw-block' style={{ position: 'relative' }}>
        <ShapeGrid
          css={{
            position: 'absolute',
            left: 0,
            bottom: -180,
          }}
          alignX='left'
          alignY='bottom'
          shapes={[
            {
              x: 1,
              y: 1,
              type: 'wedge',
              color: tinycolor(currentTestimonial.color)
                .setAlpha(0.2)
                .toRgbString(),
              fill: true,
              rotate: -90,
            },
            {
              x: 0,
              y: 0,
              type: 'square',
              color: tinycolor(currentTestimonial.color)
                .setAlpha(0.8)
                .toRgbString(),
              fill: true,
            },
          ]}
        />

        <Logos
          className='tw-mt-6 tw-mb-14'
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          onMouseEnter={() => setAutomated(false)}
          onMouseLeave={() => setAutomated(true)}
        />

        <div className='tw-flex tw-align-stretch'>
          <div css={{ flex: 1 }} className='tw-flex tw-justify-end tw-mr-18'>
            <DesktopBrowser
              className='box-shadow'
              css={{ width: '75%' }}
              height={700}
              onMouseEnter={() => setAutomated(false)}
              onMouseLeave={() => setAutomated(true)}
              ref={setBrowserNode}
            >
              <If condition={inViewport}>
                {TESTIMONIALS.map((testimonial) => (
                  <FadeOutInTransition
                    key={testimonial.id}
                    fadeInDelay={transitionDuration}
                    fadeInDuration={transitionDuration}
                    fadeOutDuration={transitionDuration}
                    in={testimonial === currentTestimonial}
                  >
                    <HandbookImage
                      css={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                      `}
                      scrollY={!browserRatio ? 0 : browserRatio * 1250}
                      src={testimonial.handbook}
                      width='100%'
                    />
                  </FadeOutInTransition>
                ))}
              </If>
            </DesktopBrowser>
          </div>
          <div css={{ flex: 1, position: 'relative' }}>
            <If condition={inViewport}>
              {TESTIMONIALS.map((testimonial) => (
                <FadeOutInTransition
                  key={testimonial.id}
                  fadeInDelay={transitionDuration}
                  fadeInDuration={transitionDuration}
                  fadeOutDuration={transitionDuration}
                  in={testimonial === currentTestimonial}
                >
                  <div
                    className='mt-sm'
                    css={css`
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                    `}
                  >
                    <Shape
                      type='quotes'
                      color='sunshine-500'
                      size={50}
                      className='tw-mb-8'
                    />

                    <Typography variant='quote' className='tw-mb-10'>
                      {testimonial.quote}
                    </Typography>

                    <Profile testimonial={testimonial} />
                  </div>
                </FadeOutInTransition>
              ))}
            </If>
          </div>
        </div>
      </div>
    </Waypoint>
  )
}

const MobileTestimonials = () => (
  <div className='lg:tw-hidden'>
    {TESTIMONIALS.filter((t) => t.isMobile).map((testimonial) => (
      <div
        className='tw-flex tw-flex-col tw-items-center tw-mt-14'
        key={testimonial.id}
      >
        <img
          alt={`Logo of ${testimonial.logo}`}
          src={testimonial.logo}
          className='tw-mb-6'
          css={{ maxHeight: 60, maxWidth: 200 }}
        />
        <Typography variant='quote' className='text-center tw-mb-6'>
          “{testimonial.quote}”
        </Typography>
        <Profile testimonial={testimonial} />
      </div>
    ))}
  </div>
)

export default () => (
  <Section id='testimonials' className='container py-sm'>
    <MaxWidthContainer className='tw-mx-auto text-center mb-sm' xs={780}>
      <Typography variant='h3' className='lg:tw-mb-10'>
        You're in Good Company
      </Typography>
      <Typography variant='headline'>
        Thousands of companies trust Blissbook with their policy management.
      </Typography>
    </MaxWidthContainer>

    <DesktopTestimonials />
    <MobileTestimonials />
  </Section>
)
