import { sanitizeHtml } from '@blissbook/lib/sanitize'
import { BackLink, Button, Field } from '@blissbook/ui/lib'
import React, { useState } from 'react'
import Card from '../Card'
import Typography from '../Typography'
import { MailchimpForm } from '../mailchimp'

const transition = 'all ease 500ms'

export default ({ post, ...props }) => {
  const [showForm, setShowForm] = useState(false)
  return (
    <Card
      {...props}
      color='purple-500'
      title='No Spam'
      titleCss={{ opacity: 0.5 }}
    >
      <MailchimpForm>
        {({
          handleSubmit,
          resetForm,
          status: { hasSubmitted, isSubmitting, lastError } = {},
          values,
        }) => (
          <Choose>
            <When condition={lastError}>
              <Typography
                variant='p2'
                // biome-ignore lint/security/noDangerouslySetInnerHtml: filtered manually
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(lastError) }}
              />
              <BackLink css={{ fontSize: 14 }} onClick={() => resetForm()}>
                Try Again
              </BackLink>
            </When>
            <When condition={!hasSubmitted}>
              <form
                noValidate
                onSubmit={handleSubmit}
                css={{
                  maxHeight: 258,
                  overflow: 'hidden',
                }}
              >
                <Typography
                  variant='p1'
                  className='tw-mb-2'
                  css={{ transition }}
                  style={{
                    marginTop: showForm ? -144 : 0,
                    opacity: showForm ? 0 : 1,
                  }}
                >
                  Get the best HR content delivered to your inbox every other
                  Friday.
                </Typography>

                <fieldset disabled={isSubmitting}>
                  <Field
                    id='newsletter-email'
                    name='email'
                    label='Email Address'
                    onFocus={() => setShowForm(true)}
                    placeholder='name@company.com'
                    size='sm'
                  />
                </fieldset>

                <fieldset
                  css={{ transition }}
                  disabled={isSubmitting}
                  style={{
                    opacity: showForm ? 1 : 0,
                  }}
                >
                  <Field
                    id='newsletter-name'
                    name='name'
                    label='First Name'
                    placeholder='Leslie'
                    size='sm'
                  />

                  <Button type='submit' color='purple' size='sm'>
                    Sign Up
                  </Button>
                </fieldset>
              </form>
            </When>
            <Otherwise>
              <Typography variant='p1' className='tw-mb-2'>
                Success!
              </Typography>
              <Typography variant='p1' className='tw-mb-6'>
                You're gettin' the Bugle, {values.name}.
              </Typography>
              <img alt='buble' src='/images/marketing/Bugle.svg' />
            </Otherwise>
          </Choose>
        )}
      </MailchimpForm>
    </Card>
  )
}
