import { Head } from '@blissbook/ui/lib'
import React, { Fragment } from 'react'
import { Footer, Navbar } from './nav'

export default ({ children, className, ...props }) => (
  <Fragment>
    <Head {...props} />
    <Navbar />
    <div id='main'>
      <article children={children} className={className} />
    </div>
    <Footer />
  </Fragment>
)
