import { formatErrorSentence } from '@blissbook/ui/util/errors'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { subscribeToNewsletter } from '../actions'

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
  name: Yup.string().required(),
})

export const MailchimpForm = (props) => (
  <Formik
    {...props}
    initialValues={{
      email: '',
      name: '',
    }}
    onSubmit={async (values, { setStatus }) => {
      setStatus({ isSubmitting: true })

      try {
        await subscribeToNewsletter(values)
        setStatus({ hasSubmitted: true })
      } catch (error) {
        const lastError = formatErrorSentence(error)
        setStatus({ lastError })
      }
    }}
    validationSchema={validationSchema}
    validateOnBlur={false}
  />
)
