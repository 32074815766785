import '@blissbook/ui/util/vendor'
import './vendor'
import './styles'
import { ErrorBoundary } from '@blissbook/ui/lib/error/boundary'

import config from '@blissbook/ui-config'
import { history } from '@blissbook/ui/util/history'
import { bootIntercom } from '@blissbook/ui/util/integrations/intercom'
import { initializeSentry } from '@blissbook/ui/util/integrations/sentry'
import { StoreProvider } from '@blissbook/ui/util/store'
import Cookies from 'js-cookie'
import qs from 'query-string'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { HotMarketingPage } from '.'
import ScrollToHash from './ScrollToHash'
import { createStore } from './store'

const initialState = window.__STATE__
// biome-ignore lint/performance/noDelete: app relies on this behavior instead of being undefined
delete window.__STATE__

initializeSentry()

const store = createStore(initialState)

declare global {
  interface Window {
    _hsq?: string[][]
  }
}

const handleHubspotTrackingCode = (pathname: string) => {
  const { _hsq } = window

  if (_hsq) {
    if (['/sign-in', '/sign-out', '/sign-up'].includes(pathname)) {
      _hsq.push(['doNotTrack'])
    } else {
      _hsq.push(['setPath', pathname])
      _hsq.push(['trackPageView'])
    }
  }
}

if (typeof window !== 'undefined') {
  handleHubspotTrackingCode(window.location.pathname)

  history.listen((location) => {
    handleHubspotTrackingCode(location.pathname)
  })
}

// Render the app
ReactDOM.hydrate(
  <ErrorBoundary>
    <StoreProvider store={store}>
      <Router history={history}>
        <ScrollToHash>
          <HotMarketingPage />
        </ScrollToHash>
      </Router>
    </StoreProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
)

// Store utm information in cookies
const { utm_campaign, utm_content, utm_medium, utm_source, utm_term } =
  qs.parse(location.search)

const utmCookieOptions = {
  expires: 180,
  secure: !config.env.local,
}

if (utm_campaign) Cookies.set('utm_campaign', utm_campaign, utmCookieOptions)
if (utm_content) Cookies.set('utm_content', utm_content, utmCookieOptions)
if (utm_medium) Cookies.set('utm_medium', utm_medium, utmCookieOptions)
if (utm_source) Cookies.set('utm_source', utm_source, utmCookieOptions)
if (utm_term) Cookies.set('utm_term', utm_term, utmCookieOptions)

// Initialize intercom
bootIntercom(store.state)
