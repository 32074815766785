import type { RootExpression } from '@blissbook/lib/expression'
import { immerable } from 'immer'
import {
  type IntegrationProviderId,
  integrationProviders,
} from './integration/providers'
import { fromJSON, mapJSONValues } from './util/json'

export class Integration {
  id: number
  providerId: IntegrationProviderId
  rejectPeopleExpression: RootExpression | null

  lastErrorAt: Date | null
  lastError: string | null
  lastSyncAt: Date | null

  integrationName: string | undefined

  static get ATTRIBUTES() {
    return {
      createdAt: fromJSON.date,
      updatedAt: fromJSON.date,
      lastSyncAt: fromJSON.date,
      lastErrorAt: fromJSON.date,
    }
  }

  // Map attributes
  static mapJSON(json: any) {
    return mapJSONValues(json, Integration.ATTRIBUTES)
  }

  // Create a handbook from JSON
  static fromJSON(json: any) {
    return Object.assign(new Integration(), Integration.mapJSON(json))
  }

  get provider() {
    return integrationProviders.findById(this.providerId)
  }

  get url() {
    return `/integrations/${this.id}`
  }

  onSync(lastError?: string) {
    if (lastError) {
      this.lastErrorAt = new Date()
      this.lastError = lastError
    } else {
      this.lastSyncAt = new Date()
      this.lastErrorAt = null
      this.lastError = null
    }
  }
}

// @ts-ignore: immerable
Integration[immerable] = true
