import { gridBreakpoints } from '@blissbook/ui/branding'
import omit from 'lodash/omit'
import React from 'react'

const colWidths = ['xs', 'sm', 'md', 'lg', 'xl']

export const MaxWidthContainer = (props) => {
  const otherProps = omit(props, colWidths)

  const css = colWidths.reduce((css, key) => {
    const maxWidth = props[key]
    if (maxWidth !== undefined) {
      const breakpoint = gridBreakpoints[key]
      if (breakpoint) {
        css[`@media (min-width: ${breakpoint}px)`] = { maxWidth }
      } else {
        css.maxWidth = maxWidth
      }
    }
    return css
  }, {})

  return <div {...otherProps} css={css} />
}
