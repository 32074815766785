import {
  type ReplaceHashOptions,
  replaceHash,
  trimHash,
} from '@blissbook/ui/util/history'
import $ from 'jquery'

// Interface to the window's root node for scrolling
class WindowScrollNode {
  getBoundingClientRect() {
    return document.body.getBoundingClientRect()
  }

  get scrollTop() {
    return document.body.scrollTop || document.documentElement.scrollTop
  }

  set scrollTop(scrollTop) {
    document.body.scrollTop = scrollTop
    document.documentElement.scrollTop = scrollTop
  }
}
export const windowScrollNode = new WindowScrollNode()

// Get the top y coordinate of this hash
export function getHashTop(hash: string) {
  // Sanitize
  hash = trimHash(hash)

  // No hash = top of the page
  if (!hash) return 0

  // Find the top of the hash (must exist)
  let $targetEl = $(`a[name="${hash}"]:visible`)
  if ($targetEl.length === 0) {
    // this is the default browser behavior
    $targetEl = $(`[id="${hash}"]:visible`)
  }
  return $targetEl.length ? $targetEl.offset().top : undefined
}

export type ScrollToHashOptions = ReplaceHashOptions & {
  delay?: number
}

// Scroll to the hash, optionally with a delay
export async function scrollToHash(
  hash: string,
  { delay }: ScrollToHashOptions = {},
): Promise<void> {
  // Determine where to go and if we're already there, we're done
  const top = getHashTop(hash)
  if (top === undefined || top === windowScrollNode.scrollTop) return

  // No animation
  if (!delay) {
    windowScrollNode.scrollTop = top
    return
  }

  // Animate
  return new Promise((resolve) => {
    $('html, body').animate({ scrollTop: top }, delay, () => resolve())
  })
}

// Scrol to this hash and replace the URL
export async function scrollToHashAndReplace(
  hash: string,
  options?: ScrollToHashOptions,
) {
  replaceHash(hash, options)
  return scrollToHash(hash, options)
}
